import React from "react";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  lowerBlock: {
    width: "48%",
    backgroundColor: theme.palette.background.default,
    minHeight: 200,
    boxShadow: "0px 60px 50px rgba(67,67,68, 0.109804)",
  },
  lowerBlockMobile: {
    backgroundColor: theme.palette.background.default,
    boxShadow: "0px 60px 50px rgba(67,67,68, 0.109804)",
  },
  lowerBlockContent: {
    backgroundColor: theme.palette.background.default,
    width: "84%",
  },
  rndEd: {
    borderRadius: 10,
  },
  mBt: {
    marginBottom: theme.spacing(2),
  },
  centered: {
    textAlign: "center",
  },
  higher: {
    marginTop: -45,
  },
  padContent: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(
      1
    )}px 0`,
  },
  padContentMobile: {
    padding: theme.spacing(2),
  },
  title: {
    paddingTop: theme.spacing(2),
    minHeight: 45,
    fontWeight: 600,
  },
  titleMobile: {
    paddingTop: theme.spacing(2),
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    textAlign: "center"
  },
  cardContent: {
    marginTop: 0,
  },
  full: {
    flexGrow: 1,
    maxWidth: "100%",
    flexBasis: "100%",
  },
  half: {
    flexGrow: 0,
    maxWidth: "50%",
    flexBasis: "50%",
  },
}));

const Award = ({ title, image, content, matches, imgWidthPrct = "50%" }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      direction="column"
      className={clsx(
        {
          [classes.lowerBlock]: matches,
          [classes.lowerBlockMobile]: !matches,
        },
        classes.rndEd,
        classes.mBt
      )}
    >
      <Grid
        item
        container
        direction="row"
        alignItems="center"
      >
        {matches && <Grid item className={classes.half} />}
        <Grid
          item
          className={clsx({
            [classes.half]: matches,
            [classes.full]: !matches,
          })}
        >
          <Typography
            variant="h6"
            className={clsx({
              [classes.title]: matches,
              [classes.titleMobile]: !matches,
            })}
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          className={clsx(classes.centered, {
            [classes.higher]: matches,
            [classes.half]: matches,
            [classes.full]: !matches,
          })}
        >
          <img src={image} alt="tmp" width={imgWidthPrct} height="auto" />
        </Grid>
        <Grid
          item
          className={clsx({
            [classes.half]: matches,
            [classes.full]: !matches,
          })}
        >
          <div
            className={clsx(classes.padContent, {
              [classes.padContentMobile]: !matches,
            })}
          >
            <Typography
              variant="body2"
              color="textPrimary"
              className={classes.cardContent}
            >
              {content}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Award;
